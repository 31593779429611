document.addEventListener('DOMContentLoaded', () => {
  const buttons = document.querySelectorAll('#footer [data-action]')

  if (buttons.length === 0) {
    return
  }

  buttons.forEach((element) => {
    element.addEventListener('click', (event) => {
      const action = element.getAttribute("data-action");

      switch (action) {
        case 'cookiebotConsentUpdate':
          if (window.hasOwnProperty('Cookiebot')) {
            window.Cookiebot.renew();
          }
          break;
        default:
          console.log(`${action} not supported`);
      }
    });
  });

})
